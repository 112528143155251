@font-face {
  font-family: 'FiraGO';
  src: url('./FiraGO/Roman/FiraGO-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'FiraGO';
  src: url('./FiraGO/Italic/FiraGO-Italic.woff2') format('woff2');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'FiraGO';
  src: url('./FiraGO/Roman/FiraGO-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'FiraGO';
  src: url('./FiraGO/Italic/FiraGO-BoldItalic.woff2') format('woff2');
  font-style: italic;
  font-weight: 700;
}

body {
  margin: 0;
  background: black;
  color: white;
  font-family: 'FiraGO',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.35em;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1, h2 {
  line-height: 1.15em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
