.navbar {
    --primary-color:#e25231;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
}

.links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    justify-content: space-between;
    width: 100%;
}

.links a {
    display: block;
    text-decoration: none;
    color: var(--primary-color);
    border-bottom: solid black 2px;
}

.links a:hover {
    border-bottom: solid var(--primary-color) 2px;
}

.classes {
    position: relative;
}

.classesHeader {
    cursor: pointer;
    color: var(--primary-color);
}

.classList{
    position: absolute;
    list-style: none;
    padding: 0.5em 0.5em;
    background: black;
}

.classList li {
    padding: 0;
    position: relative;
    margin-bottom: 0.5em;
}

@media only screen and (max-width: 1100px) {
    .navbar {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .links {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto auto;
    }

    .links a {
        text-align: center;
    }

    .classesHeader {
        display: block;
        text-align: center;
    }
}