.page {
    --primary-color:#e25231;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto 1fr;
    grid-template-areas: 
      "image details"
      "schedule details";
    gap: 2em;
}

.details {
    grid-area: details;
}

.page h1, .page h2 {
    color: var(--primary-color);
    line-height: 1.25em;
}

.scheduleTable {
    display: inline;
    grid-area: schedule;
}

.scheduleTable caption{
    text-align: left;
}

.scheduleTable td {
    padding: 0.15em 0.15em;
    
}

.scheduleTable th {
    text-align: left;
    color: var(--primary-color);
}

@media only screen and (max-width: 1150px) {
    .page {
        display: flex;
        flex-direction: column;
        gap: 0;
    }
}
