.contactUsPage {
    --primary-color:#e25231;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto 1fr;
    grid-template-areas: 
      "left address"
      "left info";
    column-gap: 2em;
}

.container {
    grid-area: left;
}

.socialMediaLink {
    text-decoration: none;
    color: var(--primary-color);
}

.socialMedia{ 
    /*grid-area: left;*/
}

.socialMedia h2 {
    color: var(--primary-color);
}

.mapSection {
    /*grid-area: left;*/
}

.map {
    width: 450px;
    height: 300px;
    margin-bottom: 1em;
}

.mapSection h2 {
    color: var(--primary-color);
}

.info {
    grid-area: info;
    /* display: flex; *.
    /* flex-direction: column; */
    /* margin-top: 1em; */
}

.address {
    grid-area: address;
    color: var(--primary-color);
}

.contactForm input,
.contactForm textarea {
    font-family: inherit;
    border-radius: 0.5em;
    border: solid 1px black;
    padding: 0.5em;
    font-size: 1rem;
}

.contactForm label {
    color: var(--primary-color);
    /* font-weight: bold; */
}

.contactForm input {
    margin-bottom: 0.5em;
}

.contactForm textarea {
    resize: none;
    height: 8em;
}

.submitButton {
    border:none;
    border-radius: 0.5em;
    cursor: pointer;
    margin-top: 1em;
    height: 2em;
    font-weight: bold;
    font-size: 0.85em;
    background: var(--primary-color);
}

.submitButton:active {
    transform: translate(0, 0.15em);
}

@media only screen and (max-width: 1320px) {
    .map {
        width: 300px;
        height: 200px;
    }
}

@media only screen and (max-width: 900px) {
    .contactUsPage {
        display: flex;
        flex-direction: column;
    }

    .contactInfo {
        order: -1;
    }
    
}
