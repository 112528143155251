.aboutUsPage{
    --primary-color:#e25231;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: "picture details";
    column-gap: 5em;
    align-items: center;
}

.aboutUsPage h2 {
    margin: 0;
    color: var(--primary-color);
}

.picOfSifu {
    grid-area: picture;
    margin: 0;
}

.details {
    grid-area: details;
}

@media only screen and (max-width: 1000px) {
    .aboutUsPage{
        display: flex;
        flex-direction: column;
    }

    .picOfSifu {
        margin-bottom: 1em;
    }
}