.homePage {
    --primary-color: #e25231;
    --media-width: 480px;
    --media-height: 288px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas: 
      "header header"
      "media description"
      "media description";
    column-gap: 2em;
}

.welcomeMsg {
    grid-area: header;
    color: var(--primary-color);
}

.description {
    grid-area: description;
}

.description p:first-child {
    margin-top: 0;
}

.media {
    grid-area: media;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.groupPic {
    width: var(--media-width);
    height: var(--media-height);
    margin: 0 auto 2em auto;
}

.performanceVideo {
    width: var(--media-width);
    height: var(--media-height);
    margin: 0 auto;
}

@media only screen and (max-width:1150px) {
    .homePage {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 700px) {
    .homePage {
        --media-width: 300px;
        --media-height: 180px;
    }

    .groupPic {
        width: var(--media-width);
        height: var(--media-height);
    }
    
    .performanceVideo {
        width: var(--media-width);
        height: var(--media-height);
    }
}
