.App {
  padding-bottom: 2em;
}

.header{ 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6.5em;
}

.logo{ 
  width: 200px;
  height: 133.3px
}

.mainContent {
  padding: 0em 13em;
}

@media only screen and (max-width: 1246px) {
  .mainContent {
    padding: 0em 10em;
  }
}

@media only screen and (max-width: 900px) {
  .header {
    flex-direction: column;
    align-items: center;
}
}

@media only screen and (max-width: 800px) {
  .mainContent {
    padding: 0em 4em;
  }

  .header{ 
    padding: 0 1.5em;
  }
}

@media only screen and (max-width: 450px) {
  .header {
      flex-direction: column;
      align-items: center;
  }

  .mainContent {
    padding: 0em 1.5em;
  }

}


